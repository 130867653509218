import '../sass/main.scss'
import lazyframe from 'lazyframe';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'G-YD8Z3754X7');

// LAZYFRAME //
lazyframe(".lazyframe");

// STICKY HEADER //
import("./components/show-hide-sticky-header").then((init) => init.default());

// light gallery //
import("./components/light-gallery").then((init) => init.default());

// Animate on scroll ////
AOS.init();